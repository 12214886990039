import React from 'react';
import { center, left, right } from '../marks/alignment';
import Layout from '../components/layout';
import SEO from '../components/SEO';
import { graphql } from 'gatsby';
import Image from 'gatsby-plugin-sanity-image';
import BlockContent from '@sanity/block-content-to-react';

export default ({ data, location }) => {
  const { name, _rawDescription, image } = data.sanityComedian;

  return (
    <Layout location={location}>
      <SEO title={name} />
      <div className={'grid'} style={{ gridTemplateColumns: '1fr 2fr' }}>
        <Image
          className={'max-w-full mr-4 w-64 shadow-md border border-gray-800'}
          {...image}
          alt={`Image of ${name}`}
        />
        <div>
          <h1>{name}</h1>
          <BlockContent blocks={_rawDescription} serializers={{ marks: { left, center, right } }} />
        </div>
      </div>
    </Layout>
  );
};

export const query = graphql`
  query Comedian($id: String) {
    sanityComedian(id: { eq: $id }) {
      _rawDescription
      name
      image {
        ...ImageWithPreview
      }
    }
  }
`;
